
import { TaskProposed } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { AlertModule } from '@/modules/alert/store'
import { DiagramModule } from '@/modules/diagram/store'
import * as diff from '@/modules/editor/helpers/diff'
import { EditorModule } from '@/modules/editor/store'
import { FlowModule } from '@/modules/flow/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { VersionModule } from '@/modules/version/store'

@Component({
  components: {
    Dialog
  },
  name: 'DiagramProposedMergeDialog'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly scrollRef!: HTMLElement

  merging = false

  scrollYVisible = false

  currentTab: 'all' | 'created' | 'updated' | 'removed' = 'all'

  tasksProposedDryRunCache: ({ error?: string } & TaskProposed)[] | undefined
  draftChangesCache: diff.IDiffChanges | undefined

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get diagramHandleId () {
    return this.$queryValue('diagram_proposed_merge')
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get diagram () {
    return this.diagramHandleId ? Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.diagramHandleId) : undefined
  }

  get diagramContent () {
    return this.diagram ? this.diagramModule.diagramContents[this.diagram.id] : undefined
  }

  get diagramParent () {
    return this.diagram?.parentId ? this.diagramModule.diagrams[this.diagram.parentId] : undefined
  }

  get diagramDrafts () {
    return Object.values(this.diagramModule.diagrams).filter(o => o.parentId === this.diagramParent?.id)
  }

  get diagramDraftsCount () {
    return this.diagramDrafts.length
  }

  get tabs () {
    return [
      {
        caption: this.createdChangeDescriptions.length + this.updateChangeDescriptions.length + this.removeChangeDescriptions.length,
        id: 'all',
        text: 'All'
      },
      {
        caption: this.createdChangeDescriptions.length,
        icon: '$fas-plus',
        iconColor: 'green',
        id: 'created',
        text: 'Created'
      },
      {
        caption: this.updateChangeDescriptions.length,
        icon: '$fas-sync-alt',
        iconColor: 'info',
        id: 'updated',
        text: 'Updated'
      },
      {
        caption: this.removeChangeDescriptions.length,
        icon: '$fas-times',
        iconColor: 'error',
        id: 'removed',
        text: 'Removed'
      }
    ]
  }

  get tasksProposedDryRun () {
    return this.tasksProposedDryRunCache || this.editorModule.tasksProposedDryRun(this.diagramContent?.tasksProposed || [])
  }

  get tasksProposedDryRunErrors () {
    return this.tasksProposedDryRun.map(o => o.error).filter((o): o is string => !!o)
  }

  get tasksProposedDryRunSuccess () {
    return this.tasksProposedDryRun.every(o => !o.error)
  }

  get currentChanges () {
    let descriptions: diff.IDiffDescription[] = []
    if (this.currentTab === 'all') {
      descriptions = this.allChangeDescriptions
    } else if (this.currentTab === 'created') {
      descriptions = this.createdChangeDescriptions
    } else if (this.currentTab === 'updated') {
      descriptions = this.updateChangeDescriptions
    } else if (this.currentTab === 'removed') {
      descriptions = this.removeChangeDescriptions
    }

    const tasksProposed = this.diagramContent?.tasksProposed || []
    const tasksProposedDryRun = this.tasksProposedDryRun
    return descriptions.map(o => {
      const taskProposed = tasksProposed
        .find(t => 'id' in t.task ? t.task.id === o.id && t.task.type === o.type : false)
      const errors = tasksProposedDryRun
        .filter(t => t.task && 'id' in t.task ? t.task.id === o.id && t.task.type === o.type : false)
        .map(o => o.error)
        .filter((o): o is string => !!o)
      const user = taskProposed && this.currentLandscape ? this.organizationModule.organizationUsers[this.currentLandscape.organizationId]?.[taskProposed.proposedById] : undefined
      return {
        description: o,
        errors,
        taskProposed,
        user
      }
    })
      .sort((a, b) => a.taskProposed && b.taskProposed ? b.taskProposed.proposedAt.localeCompare(a.taskProposed.proposedAt) : 0)
  }

  get currentChangesCount () {
    return this.currentChanges
  }

  get allChangeCount () {
    return this.allChangeDescriptions.length
  }

  get allChangeDescriptions () {
    return diff.describeChanges(this.draftChangesCache || this.editorModule.draftChanges, {
      modelConnections: this.modelModule.connections,
      modelObjects: this.modelModule.objects
    })
  }

  get createdChangeDescriptions () {
    return diff.describeChanges({
      created: this.draftChangesCache?.created || this.editorModule.draftChanges.created
    }, {
      modelConnections: this.modelModule.connections,
      modelObjects: this.modelModule.objects
    })
  }

  get updateChangeDescriptions () {
    return diff.describeChanges({
      updated: this.draftChangesCache?.updated || this.editorModule.draftChanges.updated
    }, {
      modelConnections: this.modelModule.connections,
      modelObjects: this.modelModule.objects
    })
  }

  get removeChangeDescriptions () {
    return diff.describeChanges({
      removed: this.draftChangesCache?.removed || this.editorModule.draftChanges.removed
    }, {
      modelConnections: this.modelModule.connections,
      modelObjects: this.modelModule.objects
    })
  }

  @Watch('currentChangesCount')
  async onCurrentChangesCountChanged () {
    await this.$nextTick()
    this.scrollYVisible = this.scrollRef ? this.scrollRef.scrollHeight > this.scrollRef.clientHeight : false
  }

  async merge () {
    try {
      const diagramContent = this.diagramContent
      const diagramParentId = this.diagram?.parentId
      if (!this.diagram || !diagramParentId || !diagramContent || !this.diagramParent || !this.tasksProposedDryRunSuccess) {
        return
      }

      this.merging = true

      this.draftChangesCache = this.editorModule.draftChanges
      this.tasksProposedDryRunCache = this.tasksProposedDryRun

      for (const { task } of diagramContent.tasksProposed) {
        if (task.type === 'model-object-create') {
          const { object, objectUpsert } = this.modelModule.generateObject(diagramContent.landscapeId, diagramContent.versionId, task.props, task.id)
          this.modelModule.setObject(object)

          try {
            await this.modelModule.objectUpsert({
              landscapeId: diagramContent.landscapeId,
              objectId: object.id,
              props: objectUpsert,
              versionId: diagramContent.versionId
            })
          } catch (err: any) {
            throw new Error(`${err.body?.message ?? err.message} (${object.name})`)
          }
        } else if (task.type === 'model-connection-create') {
          const { connection, connectionUpsert } = this.modelModule.generateConnection(diagramContent.landscapeId, diagramContent.versionId, task.props, task.id)
          this.modelModule.setConnection(connection)

          try {
            await this.modelModule.connectionUpsert({
              connectionId: connection.id,
              landscapeId: diagramContent.landscapeId,
              props: connectionUpsert,
              versionId: diagramContent.versionId
            })
          } catch (err: any) {
            throw new Error(`${err.body?.message ?? err.message} (${connection.name})`)
          }
        } else if (task.type === 'model-object-update') {
          const { object, objectUpdate } = this.modelModule.generateObjectCommit(task.id, task.props, this.modelModule.objectsCurrent[task.id])
          this.modelModule.setObjectVersion(object)

          try {
            await this.modelModule.objectUpdate({
              landscapeId: diagramContent.landscapeId,
              objectId: object.id,
              props: objectUpdate,
              versionId: diagramContent.versionId
            })
          } catch (err: any) {
            throw new Error(`${err.body?.message ?? err.message} (${object.name})`)
          }
        } else if (task.type === 'model-connection-update') {
          const { connection, connectionUpdate } = this.modelModule.generateConnectionCommit(task.id, task.props, this.modelModule.connectionsCurrent[task.id])
          this.modelModule.setConnectionVersion(connection)

          try {
            await this.modelModule.connectionUpdate({
              connectionId: connection.id,
              landscapeId: diagramContent.landscapeId,
              props: connectionUpdate,
              versionId: diagramContent.versionId
            })
          } catch (err: any) {
            throw new Error(`${err.body?.message ?? err.message} (${connection.name})`)
          }
        } else if (task.type === 'model-object-delete') {
          const id = task.id
          this.modelModule.removeObject(id)

          try {
            await this.modelModule.objectDelete({
              landscapeId: diagramContent.landscapeId,
              objectId: id,
              versionId: diagramContent.versionId
            })
          } catch (err: any) {
            throw new Error(`${err.body?.message ?? err.message} (${this.modelModule.objects[id]?.name})`)
          }
        } else if (task.type === 'model-connection-delete') {
          const id = task.id
          this.modelModule.removeConnection(id)

          try {
            await this.modelModule.connectionDelete({
              connectionId: id,
              landscapeId: diagramContent.landscapeId,
              versionId: diagramContent.versionId
            })
          } catch (err: any) {
            throw new Error(`${err.body?.message ?? err.message} (${this.modelModule.connections[id].name})`)
          }
        }
      }

      if (diagramParentId) {
        await this.diagramModule.diagramContentFind({
          diagramId: diagramParentId,
          landscapeId: diagramContent.landscapeId,
          versionId: diagramContent.versionId
        })

        const parentDiagramUpdate = this.diagramModule.generateDiagramContentCommit(diagramParentId, {
          connections: {
            $replace: diagramContent.connections
          },
          objects: {
            $replace: diagramContent.objects
          },
          tasksProposed: {
            $replace: []
          }
        })
        this.diagramModule.setDiagramContentVersion(parentDiagramUpdate.diagramContent)
        this.editorModule.addToTaskQueue({
          func: () => this.diagramModule.diagramContentUpdate({
            diagramId: parentDiagramUpdate.diagramContent.id,
            landscapeId: parentDiagramUpdate.diagramContent.landscapeId,
            props: parentDiagramUpdate.diagramContentUpdate,
            versionId: parentDiagramUpdate.diagramContent.versionId
          })
        })

        const deleteFunc: (() => Promise<any>)[] = []

        const parentDiagramFlows = Object.values(this.flowModule.flows).filter(o => o.diagramId === diagramParentId)
        parentDiagramFlows.forEach(o => {
          this.flowModule.removeFlow(o)
          deleteFunc.push(() => this.flowModule.flowDelete({
            flowId: o.id,
            landscapeId: o.landscapeId,
            versionId: o.versionId
          }))
        })

        this.editorModule.addToTaskQueue({
          func: deleteFunc
        })

        const upsertFunc: (() => Promise<any>)[] = []

        const diagramFlows = Object.values(this.flowModule.flows).filter(o => o.diagramId === this.diagram?.id)
        diagramFlows.forEach(o => {
          const { flow, flowUpsert } = this.flowModule.generateFlow(o.landscapeId, o.versionId, {
            diagramId: diagramParentId,
            handleId: o.handleId,
            labels: o.labels,
            name: o.name,
            showConnectionNames: o.showConnectionNames,
            steps: o.steps
          })
          this.flowModule.setFlowVersion(flow)
          upsertFunc.push(() => this.flowModule.flowUpsert({
            flowId: flow.id,
            landscapeId: flow.landscapeId,
            props: flowUpsert,
            versionId: flow.versionId
          }))
        })

        this.editorModule.addToTaskQueue({
          func: upsertFunc
        })
      } else {
        const diagramUpdate = this.diagramModule.generateDiagramContentCommit(this.diagram.id, {
          tasksProposed: {
            $replace: []
          }
        })
        this.diagramModule.setDiagramContentVersion(diagramUpdate.diagramContent)
        this.editorModule.addToTaskQueue({
          func: () => this.diagramModule.diagramContentUpdate({
            diagramId: diagramUpdate.diagramContent.id,
            landscapeId: diagramUpdate.diagramContent.landscapeId,
            props: diagramUpdate.diagramContentUpdate,
            versionId: diagramUpdate.diagramContent.versionId
          })
        })
      }

      await this.$replaceQuery({
        diagram: this.diagramParent.handleId
      })

      const func: (() => Promise<any>)[] = []

      this.diagramDrafts.forEach(o => {
        this.diagramModule.removeDiagram(o.id)
        this.diagramModule.removeDiagramContent(o.id)

        func.push(() => this.diagramModule.diagramDelete({
          diagramId: o.id,
          landscapeId: this.currentLandscape.id,
          versionId: this.currentVersion.id
        }))
      })

      this.editorModule.addToTaskQueue({
        func
      })

      await this.$replaceQuery({
        diagram_proposed_merge: undefined
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.body?.message ?? err.message
      })
    } finally {
      this.merging = false
      this.draftChangesCache = undefined
      this.tasksProposedDryRunCache = undefined
    }
  }
}
